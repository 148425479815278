import { Toast, ToastContainer } from '@glow/ui-components'
import i18next from 'i18next'
import React, { createContext, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'

interface ToastContextValue {
  // Show a Toast message at the bottom-center of the screen.
  setToast: (toast: Toast) => void
  // A helper for showing an Error object as a Toast message.
  setToastError: (err: ErrorResponseToast) => void
}

export interface ErrorResponseToast {
  body?: Array<{ errorType: string; description: string; params: {} }>
  status: number
}

export const ToastContext = createContext<ToastContextValue>({
  setToast: setToastPlaceholder,
  setToastError: setToastErrorPlaceholder
})

export function ToastProvider(props: { children: ReactNode }): ReactElement | null {
  const [state, setToast] = useState<Toast>()

  const setToastError = useCallback(
    (err: ErrorResponseToast) => {
      console.log(err)
      setToast({
        variant: 'error-dark',
        text: i18next.t(`errors.${err.body?.at(0)?.errorType ?? err.body}`, err.body?.at(0)?.params)
      })
    },
    [setToast]
  )

  const value = useMemo(
    () => ({
      setToast,
      setToastError
    }),
    [setToast, setToastError]
  )

  return (
    <ToastContext.Provider value={value}>
      {props.children}
      <ToastContainer toast={state} />
    </ToastContext.Provider>
  )
}

function setToastPlaceholder() {
  throw new Error('setToast used outside ToastProvider')
}

function setToastErrorPlaceholder() {
  throw new Error('setToastError used outside ToastProvider')
}
