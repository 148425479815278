import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { airExpressAccesses } from '../pages/admin/users/AdminUsersUtils'
import lazy from '../utils/lazyWithRetry'
import {
  allPlannerTypes,
  plannerPlusOrAbove,
  plannerTypesWithWriteAccess,
  ROLE_ADMIN,
  ROLE_AIREXPRESS_CUSTOMS_AGENT,
  ROLE_AIREXPRESS_HANDLER,
  ROLE_CUSTOMER,
  ROLE_PLUS_PLANNER,
  strictAdminOrPlannerTypes
} from '../utils/roles'
import { AppRoute } from './AppRoute'
const PageNotFound = lazy(() => import('../pages/NotFound'))

const requiredLoginRoles = [ROLE_ADMIN, ...allPlannerTypes, ROLE_CUSTOMER, ROLE_AIREXPRESS_HANDLER]

export function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRoute
            component={FrontPage}
            roles={[
              ROLE_ADMIN,
              ROLE_CUSTOMER,
              ROLE_AIREXPRESS_HANDLER,
              ROLE_AIREXPRESS_CUSTOMS_AGENT,
              ...allPlannerTypes
            ]}
            titleKey="root"
          />
        }
      />
      <Route
        path="/login"
        element={<AppRoute component={LoginFormPage} roles={requiredLoginRoles} titleKey="root" />}
      />
      <Route
        path="/auth/set-email"
        element={
          <AppRoute
            component={SendLoginEmailPage}
            roles={[ROLE_ADMIN, ...allPlannerTypes, ROLE_CUSTOMER]}
            titleKey="root"
          />
        }
      />
      <Route
        path="/auth/set-password/:token"
        element={
          <AppRoute
            component={SetPasswordPage}
            roles={[ROLE_ADMIN, ...allPlannerTypes, ROLE_CUSTOMER]}
            titleKey="root"
          />
        }
      />
      <Route
        path="/auth/zendesk/token"
        element={<AppRoute layout={AuthLayout} component={ZendeskAuth} titleKey="root" />}
      />

      <Route
        path="/auth/request-password"
        element={
          <AppRoute
            component={SendLoginEmailPage}
            roles={[ROLE_ADMIN, ...allPlannerTypes, ROLE_CUSTOMER]}
            titleKey="root"
          />
        }
      />
      <Route
        path="/admin/users/*"
        element={<AppRoute layout={AdminLayout} access={['ADMIN_USERS']} component={AdminUsersPage} titleKey="admin" />}
      />
      <Route
        path="/admin/vendors/*"
        element={
          <AppRoute layout={AdminLayout} access={['ADMIN_VENDORS']} component={AdminVendorsRoutes} titleKey="admin" />
        }
      />
      <Route
        path="/admin/customers/:customerId?"
        element={
          <AppRoute
            layout={AdminLayout}
            component={AdminCustomers}
            access={['ADMIN_CUSTOMER', 'ADMIN_CUSTOMER_ALL']}
            titleKey="admin"
          />
        }
      />

      <Route
        path="/admin/customers/:customerId/webhook/*"
        element={
          <AppRoute
            layout={AdminLayout}
            component={WebhookConfigurationRoutes}
            access={['ADMIN_CUSTOMER', 'ADMIN_CUSTOMER_ALL']}
            titleKey="admin"
          />
        }
      />
      <Route
        path="/webhook-messages/*"
        element={
          <AppRoute
            component={WebhookMessageRoutes}
            access={['ADMIN_CUSTOMER', 'ADMIN_CUSTOMER_ALL']}
            titleKey="admin"
          />
        }
      />

      <Route
        path="/admin/customers-groups/*"
        element={
          <AppRoute
            layout={AdminLayout}
            access={['ADMIN_CUSTOMER_GROUP']}
            component={AdminCustomersAndGroups}
            titleKey="admin"
          />
        }
      />
      <Route
        path="/admin/customerGroups"
        element={
          <AppRoute
            layout={AdminLayout}
            access={['ADMIN_CUSTOMER_GROUP']}
            component={AdminCustomerGroups}
            titleKey="admin"
          />
        }
      />
      <Route
        path="/admin/departments/*"
        element={
          <AppRoute
            layout={AdminLayout}
            access={['ADMIN_DEPARTMENT', 'ADMIN_DEPARTMENT_GROUP']}
            component={AdminDepartmentsNew}
            titleKey="admin"
          />
        }
      />

      <Route
        path="/admin/settings"
        element={
          <AppRoute layout={AdminLayout} access={['ADMIN_SETTINGS']} component={AdminSettings} titleKey="admin" />
        }
      />
      <Route
        path="/admin/optimizing/:date?"
        element={
          <AppRoute layout={AdminLayout} component={OptimizingPage} access={['ADMIN_OPTIMIZING']} titleKey="admin" />
        }
      />

      <Route
        path="/admin/numberSeries"
        element={
          <AppRoute
            layout={AdminLayout}
            access={['ADMIN_NUMBER_SERIES']}
            component={AdminNumberSeries}
            titleKey="admin"
          />
        }
      />
      <Route
        path="/admin/services-and-texts/*"
        element={
          <AppRoute
            layout={AdminLayout}
            access={['ADMIN_TEXTS']}
            component={AdminServiceMatrixAndTexts}
            titleKey="admin"
          />
        }
      />

      <Route
        path="/admin/matrix/*"
        element={<AppRoute layout={AdminLayout} access={['ADMIN_MATRIX']} component={AdminMatrices} titleKey="admin" />}
      />

      <Route
        path="/admin/jobs"
        element={<AppRoute layout={AdminLayout} access={['ADMIN_JOBS']} component={Jobs} titleKey="jobs" />}
      />
      <Route
        path="/admin/holidays"
        element={<AppRoute layout={AdminLayout} access={['ADMIN_HOLIDAYS']} component={Holidays} titleKey="holidays" />}
      />
      <Route
        path="/admin/shd"
        element={<AppRoute layout={AdminLayout} access={['ADMIN_SHD']} component={ShdAdmin} titleKey="shd" />}
      />

      <Route
        path="/admin/preadvice/*"
        element={
          <AppRoute
            layout={AdminLayout}
            component={AdminPreAdvicePage}
            access={['ADMIN_PRE_ADVICE']}
            titleKey="preadvice"
          />
        }
      />
      <Route
        path="/admin/lmEvents/:shipmentId?"
        element={
          <AppRoute layout={AdminLayout} access={['ADMIN_LM_EVENTS']} component={AdminLmEvents} titleKey="admin" />
        }
      />
      <Route
        path="/admin/cms/*"
        element={
          <AppRoute
            layout={AdminLayout}
            component={AdminCMS}
            access={['ADMIN_CMS_BANNERS', 'ADMIN_CMS_WHATS_THIS_MODALS']}
            titleKey="admin"
          />
        }
      />
      <Route
        path="/admin/user-roles/*"
        element={<AppRoute layout={AdminLayout} component={UserRolesPage} access={['ADMIN_ROLE']} titleKey="admin" />}
      />
      <Route
        path="/admin/integrations/:date?"
        element={
          <AppRoute layout={AdminLayout} component={ApolloAdmin} access={['ADMIN_LM_EVENTS']} titleKey="integrations" />
        }
      />
      <Route
        path="/planner"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={PlannerSelectDepartmentPage}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="planner"
          />
        }
      />
      <Route
        path="/planner/:departmentId/dashboards/productivity/*"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={PlannerDashboard}
            roles={[...plannerPlusOrAbove]}
            titleKey="plannerDashboard"
          />
        }
      />
      <Route
        path="/planner/:departmentId/live/:date?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Live}
            access={['PLANNER_LIVE']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerLive"
          />
        }
      />
      <Route
        path="/planner/:departmentId/live/:date/slots/:slotId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Live}
            access={['PLANNER_LIVE']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerLiveSlot"
          />
        }
      />
      <Route
        path="/planner/:departmentId/live2/:date?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Live2}
            access={['PLANNER_LIVE']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerLive"
          />
        }
      />
      <Route
        path="/planner/:departmentId/units"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Units}
            access={['PLANNER_UNITS']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerUnits"
          />
        }
      />
      <Route
        path="/planner/:departmentId/units-new/*"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={UnitsNew}
            access={['PLANNER_UNITS']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerUnits"
          />
        }
      />
      <Route
        path="/planner/:departmentId/units/:unitId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Units}
            access={['PLANNER_UNITS']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerUnits"
          />
        }
      />
      <Route
        path="/planner/:departmentId/couriers-old/*"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Couriers}
            access={['PLANNER_COURIERS']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerCouriers"
          />
        }
      />
      <Route
        path="/planner/:departmentId/couriers/*"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={CouriersPage}
            access={['PLANNER_COURIERS']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerCouriers"
          />
        }
      />
      <Route
        path="/planner/:departmentId/import/:importId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={ImportOrdersResultPage}
            access={['PLANNER_IMPORT_EXPORT', 'PLANNER_IMPORT_EXPORT_EXTENDED']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerImport"
          />
        }
      />
      <Route
        path="/planner/:departmentId/export/"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Export}
            access={['PLANNER_IMPORT_EXPORT', 'PLANNER_IMPORT_EXPORT_EXTENDED']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerImport"
          />
        }
      />
      <Route
        path="/planner/:departmentId/unresolved/"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={UnresolvedAddresses}
            access={['PLANNER_UNRESOLVED']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerUnresolved"
          />
        }
      />
      <Route
        path="/planner/:departmentId/coordinates/"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={SetCoordinates}
            access={['PLANNER_UNRESOLVED']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerCoordinates"
          />
        }
      />
      <Route
        path="/planner/:departmentId/shipments/:filter?/:fromDate?/:toDate?/:searchPhrase?/:customerAlystraId?/:shipmentId?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Shipments}
            access={['PLANNER_SHIPMENTS']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerConsignments"
          />
        }
      />

      <Route
        path="/planner/:departmentId/shipments-search/*"
        element={
          <AppRoute
            component={ShipmentPagesRoutes}
            access={['PLANNER_SHIPMENTS']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            layout={PlannerLayout}
            titleKey="plannerConsignments"
          />
        }
      />
      <Route
        path="/planner/simple-search/:shipmentId?"
        element={
          <AppRoute layout={PlannerLayout} component={SimpleSearch} roles={[ROLE_ADMIN]} titleKey="plannerSearch" />
        }
      />
      <Route
        path="/planner/advanced-search/:searchPhrase?"
        element={
          <AppRoute layout={PlannerLayout} component={AdvancedSearch} roles={[ROLE_ADMIN]} titleKey="plannerSearch" />
        }
      />
      <Route path="/planner/:departmentId/billing-order/*" element={<BillingRoutes />} />
      <Route
        path="/planner/:departmentId/invoicing/:fromDate?/:toDate?/:shipmentFilter?/:searchShipments?/:customerAlystraId?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Invoicing}
            access={['PLANNER_INVOICING']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="plannerInvoicing"
          />
        }
      />
      <Route
        path="/planner/:departmentId/invoicing-new/*"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Invoicing2}
            access={['PLANNER_INVOICING']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="plannerInvoicing"
          />
        }
      />
      <Route
        path="/planner/:departmentId/invoicing-new"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Invoicing2}
            access={['PLANNER_INVOICING']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="plannerInvoicing"
          />
        }
      />
      <Route
        path="/planner/:departmentId/manage-returns/*"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={ManageReturns}
            access={['PLANNER_MANAGE_RETURNS']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="manageReturns"
          />
        }
      />
      <Route
        path="/planner/:departmentId/preadvice-followup/*"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={PreAdviceFollowup}
            access={['PLANNER_PRE_ADVICE']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="preAdviceFollowup"
          />
        }
      />
      <Route
        path="/planner/:departmentId/planning/:date?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Planning2LeftPage}
            access={['PLANNER_ROUTE_PLANNING']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerPlanning"
          />
        }
      />
      <Route
        path="/planner/:departmentId/planning/slots/:date/receipt-list"
        element={
          <AppRoute
            component={Planning2RouteListForMultipleSlots}
            access={['PLANNER_ROUTE_PLANNING']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="routeReceiptList"
          />
        }
      />
      <Route
        path="/planner/:departmentId/planning/slots/:date/load-list"
        element={
          <AppRoute
            component={Planning2LoadListForMultipleSlots}
            access={['PLANNER_ROUTE_PLANNING']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="loadList"
          />
        }
      />
      <Route
        path="/planner/:departmentId/planning/slots/:date/:slotId?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Planning2RightPage}
            access={['PLANNER_ROUTE_PLANNING']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerPlanning"
          />
        }
      />
      <Route
        path="/planner/:departmentId/planning/slots/:date/:slotId/hd-route-list"
        element={
          <AppRoute
            component={HomeDeliveryRouteList}
            access={['PLANNER_ROUTE_PLANNING']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerPlanning"
          />
        }
      />
      <Route
        path="/planner/:departmentId/waybills/:id"
        element={<AppRoute component={Waybills} roles={[ROLE_ADMIN, ...allPlannerTypes]} titleKey="waybills" />}
      />
      <Route
        path="/planner/:departmentId/delivery-notes"
        element={
          <AppRoute component={DeliveryNotes} roles={[ROLE_ADMIN, ...allPlannerTypes]} titleKey="deliveryNotes" />
        }
      />
      <Route
        path="/planner/:departmentId/planning/slots/:date/:slotId/load-list"
        element={
          <AppRoute
            component={Planning2LoadList}
            access={['PLANNER_ROUTE_PLANNING']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerPlanning"
          />
        }
      />
      <Route
        path="/planner/:departmentId/rm/:date?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={RouteManagementPage}
            access={['PLANNER_ROUTE_MANAGEMENT']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerPlanning"
          />
        }
      />
      <Route
        path="/planner/:departmentId/vehicles"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={VehiclesOverviewPage}
            access={['PLANNER_VEHICLES']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerVehicle"
          />
        }
      />
      <Route
        path="/planner/:departmentId/vehicles/:vehicleId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={VehicleFormPage}
            access={['PLANNER_VEHICLES']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="plannerVehicle"
          />
        }
      />
      <Route
        path="/planner/:departmentId/h2-booking/create"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={H2Booking}
            access={['PLANNER_BOOKING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="h2Booking"
          />
        }
      />
      <Route
        path="/planner/:departmentId/labels/:orderLabelId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={ShipmentLabelsPage}
            access={['PLANNER_SHIPMENTS']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="shipmentLabels"
          />
        }
      />
      <Route
        path="/planner/:departmentId/route-receipt-list"
        element={
          <AppRoute component={RouteReceiptList} roles={[ROLE_ADMIN, ...allPlannerTypes]} titleKey="routeReceiptList" />
        }
      />
      <Route
        path="/planner/:departmentId/dispatch/:date?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Instant}
            access={['PLANNER_DISPATCHING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="instant"
          />
        }
      />
      <Route
        path="/planner/:departmentId/map"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={InstantMap}
            access={['PLANNER_DISPATCHING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="instantMap"
          />
        }
      />
      <Route
        path="/planner/:departmentId/map2"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={InstantMap2}
            access={['PLANNER_DISPATCHING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="instantMap2"
          />
        }
      />
      <Route
        path="/planner/:departmentId/booking/create"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Booking}
            access={['PLANNER_BOOKING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="instantBooking"
          />
        }
      />
      <Route
        path="/planner/:departmentId/booking/edit/:orderId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={BookingEdit}
            access={['PLANNER_BOOKING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="instantBooking"
          />
        }
      />
      <Route
        path="/planner/:departmentId/confirmation/:shipmentId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={OrderConfirmation}
            access={['PLANNER_BOOKING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="orderConfirmation"
          />
        }
      />
      <Route
        path="/planner/:departmentId/booking/copy/:orderId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={BookingCopy}
            access={['PLANNER_BOOKING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="instantBooking"
          />
        }
      />
      <Route
        path="/planner/:departmentId/booking/restore/:orderId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={BookingRestore}
            access={['PLANNER_BOOKING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="instantBooking"
          />
        }
      />
      <Route
        path="/planner/:departmentId/recurring"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={RecurringOrderPage}
            access={['PLANNER_RECURRING_ORDERS']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="recurringOrder"
          />
        }
      />
      <Route
        path="/planner/:departmentId/recurring/result/:date"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={RecurringOrderResultPage}
            access={['PLANNER_RECURRING_ORDERS']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="recurringOrder"
          />
        }
      />
      <Route
        path="/planner/:departmentId/corrections/:date?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={ShipmentCorrectionPage}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="corrections"
          />
        }
      />
      <Route
        path="/planner/:departmentId/addresses"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={Addresses}
            access={['PLANNER_ADDRESSES']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="addresses"
          />
        }
      />
      <Route
        path="/planner/:departmentId/booking/copy-deleted/:orderId"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={BookingCopy}
            access={['PLANNER_BOOKING']}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="instantBooking"
          />
        }
      />
      <Route
        path="/airexpress/admin"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={UploadFlightSchedule}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="airexpressAdmin"
          />
        }
      />
      <Route
        path="/airexpress/admin/importData"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={UploadFlightSchedule}
            access={['AIR_EXPRESS_ADMIN_UPLOADS']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="airexpressImportData"
          />
        }
      />
      <Route
        path="/airexpress/admin/airlines"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={AdminAirlines}
            access={['AIR_EXPRESS_ADMIN_AIRLINES']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="airexpressAirlines"
          />
        }
      />
      <Route
        path="/airexpress/admin/airports"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={AdminAirports}
            access={['AIR_EXPRESS_ADMIN_AIRPORTS']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="airexpressAirports"
          />
        }
      />
      <Route
        path="/airexpress/admin/preadvice"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={AirExpressAdminPreadvicePage}
            access={['AIR_EXPRESS_ADMIN_PRE_ADVICE']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="airexpressPreadvice"
          />
        }
      />
      <Route
        path="/airexpress/admin/flight-schedules"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={LocallyAddedFlightSchedules}
            access={['AIR_EXPRESS_ADMIN_SCHEDULES']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="airexpressLocallyAddedFlightSchedules"
          />
        }
      />
      <Route
        path="/airexpress/admin/admin-search-flights"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={AdminSearchFlights}
            access={['AIR_EXPRESS_ADMIN_FLIGHT_SEARCH']}
            roles={strictAdminOrPlannerTypes}
            titleKey="airexpressAdminSearchFlights"
          />
        }
      />
      <Route
        path="/airexpress/admin/handler-users"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={AdminHandlerUsers}
            access={['AIR_EXPRESS_ADMIN_HANDLERS']}
            roles={strictAdminOrPlannerTypes}
            titleKey="airexpressAdminHandlerUsers"
          />
        }
      />
      <Route
        path="/airexpress/admin/customs-agents"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={AdminCustomsAgentUsers}
            access={['AIR_EXPRESS_ADMIN_CUSTOMS_AGENTS']}
            roles={strictAdminOrPlannerTypes}
            titleKey="airexpressAdminCustomsAgent" // todo: add translation
          />
        }
      />
      <Route
        path="/airexpress/admin/terminals"
        element={
          <AppRoute
            layout={AirExpressAdminLayout}
            component={AdminTerminalPage}
            access={['AIR_EXPRESS_ADMIN_TERMINALS']}
            roles={strictAdminOrPlannerTypes}
            titleKey="airexpressAdminTerminals"
          />
        }
      />
      <Route
        path="/airexpress"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={SelectAirExpressDepartmentPage}
            access={airExpressAccesses}
            roles={[ROLE_ADMIN, ROLE_AIREXPRESS_HANDLER, ROLE_AIREXPRESS_CUSTOMS_AGENT, ...strictAdminOrPlannerTypes]}
            titleKey="airexpress"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/export/"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressExportWrapper}
            access={['AIR_EXPRESS_EXPORT']}
            roles={[ROLE_ADMIN, ROLE_PLUS_PLANNER]}
            titleKey="airexpressExportData"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/booking/create"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressBooking}
            access={['AIR_EXPRESS_BOOKING']}
            roles={[ROLE_ADMIN, ...strictAdminOrPlannerTypes]}
            titleKey="airexpressBooking"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/booking/copy/:orderId/:recurringTemplateId?/:recurringProcessedDate?"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressBookingCopy}
            roles={[ROLE_ADMIN, ...strictAdminOrPlannerTypes]}
            titleKey="airexpressBookingCopy"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/booking/edit/:airExpressOrderId"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressBookingEdit}
            roles={[ROLE_ADMIN, ...strictAdminOrPlannerTypes]}
            titleKey="airexpressBookingEdit"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/confirmation/:shipmentId"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressOrderConfirmation}
            roles={[ROLE_ADMIN, ...plannerTypesWithWriteAccess]}
            titleKey="airExpressBookingConfirmation"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/customs"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressShipmentsForCustoms}
            access={['AIR_EXPRESS_CUSTOMS']}
            roles={[ROLE_ADMIN, ROLE_AIREXPRESS_CUSTOMS_AGENT, ...strictAdminOrPlannerTypes]}
            titleKey="airexpressCustoms"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/shipments"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressShipments}
            access={['AIR_EXPRESS_SHIPMENTS']}
            roles={[ROLE_ADMIN, ...strictAdminOrPlannerTypes]}
            titleKey="airexpressShipments"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/manifest/:fromDate?/:toDate?/:flightId?/:searchPhrase?"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressManifest}
            access={['AIR_EXPRESS_MANIFEST']}
            roles={[ROLE_ADMIN, ROLE_AIREXPRESS_HANDLER, ...strictAdminOrPlannerTypes]}
            titleKey="airexpressManifest"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/recurring"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressRecurringOrder}
            access={['AIR_EXPRESS_RECURRING']}
            roles={[ROLE_ADMIN, ...strictAdminOrPlannerTypes]}
            titleKey="airexpressRecurringOrder"
          />
        }
      />
      <Route
        path="/airexpress/:departmentId/invoicing"
        element={
          <AppRoute
            layout={AirExpressLayout}
            component={AirExpressShipmentsForInvoicing}
            access={['AIR_EXPRESS_INVOICING']}
            roles={[ROLE_ADMIN, ...strictAdminOrPlannerTypes]}
            titleKey="airexpressInvoicing"
          />
        }
      />
      <Route
        path="/reports/gooddata"
        element={<AppRoute layout={AdminLayout} component={GoodDataReports} roles={[ROLE_ADMIN]} titleKey="report" />}
      />
      <Route
        path="/reports/datadog"
        element={<AppRoute layout={AdminLayout} component={DataDogReports} roles={[ROLE_ADMIN]} titleKey="report" />}
      />
      <Route
        path="/planner/:departmentId/pickup-orders/:fromDate?/:toDate?"
        element={
          <AppRoute
            layout={PlannerLayout}
            component={PickupOrders}
            access={['PLANNER_PICKUP_ORDERS']}
            roles={[ROLE_ADMIN, ...allPlannerTypes]}
            titleKey="pickupOrders"
          />
        }
      />

      {/* Need to redirect for now, in case someone tries to access old customer pages */}
      <Route path="/customer/*" element={<Navigate to="/portal" />} />
      {/* Need to redirect for now, in case someone tries to access old tracking pages */}
      <Route path="/tracking/:externalId" element={<Navigate to="/track/:externalId" />} />
      <Route path="/tracking/:externalId/flex/:page?" element={<Navigate to="/track/:externalId" />} />

      <Route path="/report" element={<Navigate to="/" />} />
      <Route path="/planner/:departmentId" element={<Navigate to="shipments-search" />} />
      <Route path="/admin" element={<Navigate to="/admin/departments" />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

const LoginFormPage = lazy(() => import('../pages/auth/LoginFormPage'))
const ZendeskAuth = lazy(() => import('../pages/auth/ZendeskAuth'))
const FrontPage = lazy(() => import('../pages/FrontPage'))
const AdminLayout = lazy(() => import('../layout/AdminLayout'))
const AdminUsersPage = lazy(() => import('../pages/admin/users/AdminUsersPage'))
const AdminVendorsRoutes = lazy(() => import('../pages/admin/vendors/VendorsRoutes'))
const AdminCustomers = lazy(() => import('../pages/admin/AdminCustomers'))
const AdminCustomersAndGroups = lazy(() => import('../pages/admin/customersAndGroups/AdminCustomersAndGroups'))
const AdminDepartmentsNew = lazy(() => import('../pages/admin/AdminDepartmentsPage'))
const AdminSettings = lazy(() => import('../pages/admin/Settings'))
const PlannerLayout = lazy(() => import('../layout/PlannerLayout'))
const PlannerSelectDepartmentPage = lazy(() => import('../pages/planner/PlannerSelectDepartmentPage'))
const Jobs = lazy(() => import('../pages/Jobs'))
const Holidays = lazy(() => import('../pages/Holidays'))
const PlannerDashboard = lazy(() => import('../pages/planner/dashboards/DashboardPage'))
const Live = lazy(() => import('../pages/planner/Live'))
const Live2 = lazy(() => import('../pages/planner/live2/Live2Page'))
const Planning2LeftPage = lazy(() => import('../pages/planner/planning2/Planning2UnassignedShipments'))
const Planning2RightPage = lazy(() => import('../pages/planner/planning2/Planning2RoutesOverview'))
const Units = lazy(() => import('../pages/Units'))
const UnitsNew = lazy(() => import('../pages/planner/units/Units'))
const Couriers = lazy(() => import('../pages/planner/courier/Couriers'))
const CouriersPage = lazy(() => import('../pages/planner/courier/CouriersPage'))
const UnresolvedAddresses = lazy(() => import('../pages/planner/unresolved2/UnresolvedAddresses'))
const SetCoordinates = lazy(() => import('../pages/planner/coordinates/SetCoordinatesPage'))
const HomeDeliveryRouteList = lazy(() => import('../pages/planner/HomeDeliveryRouteList'))
const Waybills = lazy(() => import('../pages/planner/Waybills'))
const DeliveryNotes = lazy(() => import('../pages/planner/DeliveryNotes'))
const Planning2LoadList = lazy(() => import('../pages/planner/planning2/Planning2LoadList'))
const RouteReceiptList = lazy(() => import('../pages/planner/RouteReceiptList'))
const Planning2RouteListForMultipleSlots = lazy(
  () => import('../pages/planner/planning2/Planning2RouteListForMultipleSlots')
)
const Planning2LoadListForMultipleSlots = lazy(
  () => import('../pages/planner/planning2/Planning2LoadListForMultipleSlots')
)
const RouteManagementPage = lazy(() => import('../pages/planner/route-management/RouteManagementPage'))
const SendLoginEmailPage = lazy(() => import('../pages/auth/SendLoginEmailPage'))
const SetPasswordPage = lazy(() => import('../pages/auth/SetPasswordPage'))
const AuthLayout = lazy(() => import('../layout/AuthLayout'))
const OptimizingPage = lazy(() => import('../pages/admin/OptimizingPage'))
const AdminNumberSeries = lazy(() => import('../pages/admin/AdminNumberSeries'))
const AdminServiceMatrixAndTexts = lazy(() => import('../pages/admin/service-matrix-and-texts'))
const AdminMatrices = lazy(() => import('../pages/admin/matrix/AdminMatricesPage'))
const Export = lazy(() => import('../pages/planner/export/ExportWrapper'))
const AirExpressExportWrapper = lazy(() => import('../pages/airexpress/AirExpressExportWrapper'))
const AdminLmEvents = lazy(() => import('../pages/admin/AdminLmEvents'))
const ApolloAdmin = lazy(() => import('../pages/admin/ApolloAdmin'))
const AdminCMS = lazy(() => import('../pages/cms/AdminCMSPage'))
const UserRolesPage = lazy(() => import('../pages/admin/user-roles/UserRolesPage'))
const Instant = lazy(() => import('../pages/instant/Instant'))
const InstantMap = lazy(() => import('../pages/instant/InstantMap'))
const InstantMap2 = lazy(() => import('../pages/instant/InstantMap2'))
const Booking = lazy(() => import('../pages/instant/Booking'))
const H2Booking = lazy(() => import('../pages/instant/h2/H2Booking'))
const Shipments = lazy(() => import('../pages/planner/Shipments'))
const PickupOrders = lazy(() => import('../pages/planner/pickup-orders/PickupOrders'))
const ShipmentCorrectionPage = lazy(() => import('../pages/instant/OrderCorrectionPage'))
const ShipmentLabelsPage = lazy(() => import('../pages/instant/ShipmentLabelsPage'))
const Addresses = lazy(() => import('../pages/instant/Addresses'))
const Invoicing = lazy(() => import('../pages/planner/invoicing/Invoicing'))
const Invoicing2 = lazy(() => import('../pages/planner/follow-up/invoicing/InvoicingPage'))
const ManageReturns = lazy(() => import('../pages/planner/follow-up/manage-returns/ManageReturnsPage'))
const PreAdviceFollowup = lazy(() => import('../pages/planner/follow-up/preadvice/PreAdviceFollowupPage'))
const BillingRoutes = lazy(() => import('../pages/planner/page-billing-order/BillingRoutes'))
const RecurringOrderPage = lazy(() => import('../pages/instant/RecurringOrderPage'))
const BookingEdit = lazy(() => import('../pages/instant/BookingEdit'))
const BookingCopy = lazy(() => import('../pages/instant/BookingCopy'))
const BookingRestore = lazy(() => import('../pages/instant/BookingRestore'))
const OrderConfirmation = lazy(() => import('../pages/instant/OrderConfirmation'))
const RecurringOrderResultPage = lazy(() => import('../pages/instant/RecurringOrderResultPage'))

const SelectAirExpressDepartmentPage = lazy(() => import('../pages/airexpress/booking/SelectAirExpressDepartmentPage'))
const AirExpressShipments = lazy(() => import('../pages/airexpress/shipment/AirExpressShipments'))
const AirExpressShipmentsForCustoms = lazy(() => import('../pages/airexpress/customs/AirExpressShipmentsForCustoms'))
const AirExpressShipmentsForInvoicing = lazy(
  () => import('../pages/airexpress/invoicing/AirExpressShipmentsForInvoicing')
)

const AirExpressManifest = lazy(() => import('../pages/airexpress/manifest/AirExpressManifest'))

const AirExpressBooking = lazy(() => import('../pages/airexpress/booking/AirExpressBooking'))
const AirExpressBookingCopy = lazy(() => import('../pages/airexpress/booking/AirExpressBookingCopy'))
const AirExpressBookingEdit = lazy(() => import('../pages/airexpress/booking/AirExpressBookingEdit'))
const AirExpressOrderConfirmation = lazy(
  () => import('../pages/airexpress/booking/confirmation/AirExpressOrderConfirmation')
)
const AirExpressRecurringOrder = lazy(() => import('../pages/airexpress/recurring/AirexpressRecurringOrderPage'))
const AdminAirports = lazy(() => import('../pages/airexpress/admin/AdminAirports'))
const AdminAirlines = lazy(() => import('../pages/airexpress/admin/AdminAirlines'))
const AdminHandlerUsers = lazy(() => import('../pages/airexpress/admin/AdminHandlerUsers'))
const AdminCustomsAgentUsers = lazy(() => import('../pages/airexpress/admin/AdminCustomsAgentd')) // todo: typo
const AdminTerminalPage = lazy(() => import('../pages/airexpress/admin/terminals/AdminTerminalPage'))
const UploadFlightSchedule = lazy(() => import('../pages/airexpress/admin/UploadFlightSchedule'))
const LocallyAddedFlightSchedules = lazy(() => import('../pages/airexpress/admin/LocallyAddedFlightSchedules'))
const AdminSearchFlights = lazy(() => import('../pages/airexpress/admin/AdminSearchFlights'))
const ImportOrdersResultPage = lazy(() => import('../pages/planner/ImportOrdersResultPage'))

const GoodDataReports = lazy(() => import('../pages/reports/GoodData'))
const DataDogReports = lazy(() => import('../pages/reports/DataDog'))

const AirExpressLayout = lazy(() => import('../layout/AirExpressLayout'))
const AirExpressAdminLayout = lazy(() => import('../layout/AirExpressAdminLayout'))
const AirExpressAdminPreadvicePage = lazy(() => import('../pages/airexpress/admin/preadvice/AdminPreadvicePage'))
const AdminPreAdvicePage = lazy(() => import('../pages/admin/preadvice/AdminPreadvicePage'))
const AdminCustomerGroups = lazy(() => import('../pages/admin/AdminCustomerGroups'))
const ShdAdmin = lazy(() => import('../pages/admin/ShdAdmin'))
const SimpleSearch = lazy(() => import('../pages/planner/SimpleSearch'))
const AdvancedSearch = lazy(() => import('../pages/planner/AdvancedSearch'))
const ShipmentPagesRoutes = lazy(() => import('../pages/shipments/ShipmentPagesRoutes'))
const VehiclesOverviewPage = lazy(() => import('../pages/planner/vehicle/VehiclesOverviewPage'))
const VehicleFormPage = lazy(() => import('../pages/planner/vehicle/VehicleFormPage'))

const WebhookConfigurationRoutes = lazy(() => import('../pages/admin/customer/webhook/WebhookConfigurationRoutes'))
const WebhookMessageRoutes = lazy(() => import('../pages/webhook-messages/WebhookMessageRoutes'))
