import { isProduction } from '@glow/common'
import { AnyAction } from 'redux'
import { ThunkDispatch as ReduxThunkDispatch, ThunkAction } from 'redux-thunk'
import { api, ConsumedResponse, QueryData } from '../../http/httpHelper'
import { httpDone } from '../../reducers/httpStatus'
import { SessionUser } from '../../types/coreEntitiesTypes'
import { AppStateType } from '../../utils/appStateReduxStore'
import { ActionType, ActionTypeWithAccess } from '../actionTypes'

export interface AnyData {
  [key: string]: any
}
export type ThunkResult<R> = ThunkAction<R, AppStateType, null, AnyAction>
export type ThunkDispatch = ReduxThunkDispatch<AppStateType, null, AnyAction>

export const fetchIfNotPresent: (
  actionType: ActionType,
  queryBody: QueryData,
  user?: SessionUser
) => ThunkResult<Promise<ConsumedResponse>> =
  (actionType: ActionType, queryBody: QueryData) => (dispatch, getState) => {
    if (httpDone(getState, actionType, queryBody)) {
      return Promise.resolve(new Response(null, { status: 200 })) as Promise<ConsumedResponse>
    } else {
      return dispatch(api.asyncQuery(actionType, queryBody))
    }
  }

export const fetchIfNotPresentWithAccess: (
  actionType: ActionTypeWithAccess,
  queryBody: QueryData,
  user: SessionUser
) => ThunkResult<Promise<ConsumedResponse>> =
  (actionType: ActionTypeWithAccess, queryBody: QueryData, user: SessionUser) => (dispatch, getState) => {
    if (httpDone(getState, actionType, queryBody)) {
      return Promise.resolve(new Response(null, { status: 200 })) as Promise<ConsumedResponse>
    } else if (
      !user.get('access').some((access) => actionType.userAccessKeys.includes(access)) ||
      (isProduction() && !actionType.roles.includes(user.get('role')))
    ) {
      // User doesn't have access to call this query
      console.warn(
        `user with role: ${user.get('role')} and access ${user.get('access').join(', ')} tried calling query ${JSON.stringify(queryBody)}`
      )
      return Promise.resolve(new Response(null, { status: 200 })) as Promise<ConsumedResponse>
    } else {
      return dispatch(api.asyncQuery(actionType, queryBody))
    }
  }

export const fetchEvenIfPresent: (
  actionType: ActionType,
  queryBody: QueryData
) => ThunkResult<Promise<ConsumedResponse>> = (actionType: ActionType, queryBody: QueryData) => (dispatch) =>
  dispatch(api.asyncQuery(actionType, queryBody))
