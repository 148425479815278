import { List, Map, Seq } from 'immutable'
import toString from 'lodash/toString'
import { AUTOSUGGEST_ADDRESSES, AUTOSUGGEST_CUSTOMERS } from '../../actions/actionTypes'
import {
  Consignment,
  DangerousGoods,
  Department,
  DepartmentIdType,
  Order,
  OrderIdType
} from '../../types/coreEntitiesTypes'
import { ImmutableMapFactory, isNotNullOrUndefined } from '../../types/immutableTypes'
import { hourMinute } from '../../utils/dateTime'
import { emptyVAS, ensureListWithOneItem, filterOrderCorrectionVas, isPureVas } from './bookingOrderFunctions'
import {
  ICustomerEventEmail,
  IMeasurement,
  IOrderForm,
  IOrderFormProps,
  newVAS,
  ValueAddedService
} from './bookingOrderTypes'
import { getServiceId } from './serviceFinders'
import { IOptimusService } from '../../domain/optimusService'
import { AppStateType } from '../../utils/appStateReduxStore'
import toUpper from 'lodash/toUpper'
import { convert, MeasurementUnit } from '../../domain/measurementUnits'
import { RouteComponentProps } from '../../hooks/withRouter'

export enum OrderAction {
  Create,
  Edit,
  Copy,
  Restore
}

export enum BookingType {
  Standard,
  H2,
  AirExpress
}

export interface BookingStateProps {
  consignments: Seq.Indexed<Consignment>
  departmentId: DepartmentIdType
  department: Department
  orderId: OrderIdType
  order?: Order
  isLoading: boolean
  services: List<IOptimusService>
  customizedServices: List<IOptimusService>
  orderNote: string | undefined
  isCopyDeletedPage?: boolean
  adrGoods?: List<DangerousGoods>
  selectedServiceId?: string
}

export interface BookingDispatchProps {
  resetForm: (formName: string) => void
  preLoadForm: (formName: string, values: Map<string, any>) => void
  mergeFormValues: (model: string, values: Map<string, any>) => void
  getOrderByIdUncached?: (orderId: OrderIdType) => any
  getDeletedOrderByIdUncached?: (orderId: OrderIdType) => any
  setPageStateValue: (key: string, value: any) => void
  getServicesFromOptimus: (
    customerNumber: string,
    subcustomer: string | null,
    pickupCountryCode: string,
    pickupPostalCode: string,
    deliveryCountryCode?: string,
    deliveryPostalCode?: string
  ) => void
  getHdServices: (
    customerNumber: string,
    subcustomer: string | null,
    deliveryCountryCode: string,
    deliveryPostalCode: string
  ) => void
  subcustomersByAlystraId: (alystraId: string) => void
  resetOrderPriceInfo?: () => void
}
type PathParamsType = { departmentId: string; orderId: string }
export type BookingOwnProps = RouteComponentProps<PathParamsType>

const SMS_VAS = newVAS('1091')

export const getValueAddedServices = (consignment: Consignment | undefined, hasSms: boolean, copy: boolean = false) => {
  const valueAddedServices = (consignment?.get('vasDetails') || List()).concat(
    hasSms ? List([SMS_VAS]) : List()
  ) as List<ValueAddedService>

  return valueAddedServices
    ? ensureListWithOneItem(
        valueAddedServices
          .filter(isNotNullOrUndefined)
          .filter(isPureVas)
          .filter((vas) => (copy ? filterOrderCorrectionVas(vas) : true)),
        emptyVAS
      )
    : List([emptyVAS()])
}

export function initForCopyOrEdit(compInst: React.Component<BookingProps>, formName: string, copy: boolean) {
  const firstConsignment = compInst.props.consignments.get(0)
  const { order, adrGoods } = compInst.props
  if (!firstConsignment) {
    return Promise.reject(new Error('No consignments found'))
  } else if (!order) {
    return Promise.reject(new Error('Order not found'))
  }

  const pickupCountry = toUpper(firstConsignment.get('pickupCountry'))
  const pickupZipCode = firstConsignment.get('pickupZipCode')
  const deliveryCountry = toUpper(firstConsignment.get('deliveryCountry'))
  const deliveryZipCode = firstConsignment.get('deliveryZipCode')
  const customerNumber = firstConsignment.get('custAlystraId')
  const subcustomer = firstConsignment.get('custAlystraSubcustomer')
  const pickupName = firstConsignment.get('pickupName')
  const deliveryName = firstConsignment.get('deliveryName')
  compInst.props.setPageStateValue(AUTOSUGGEST_CUSTOMERS + 'createOrderForm.customerId', customerNumber)
  compInst.props.setPageStateValue('selectedCustomerId', order.get('customerId'))
  compInst.props.setPageStateValue(AUTOSUGGEST_ADDRESSES + 'createOrderForm.pickupName', pickupName)
  compInst.props.setPageStateValue(AUTOSUGGEST_ADDRESSES + 'createOrderForm.deliveryName', deliveryName)
  if (firstConsignment && pickupCountry && firstConsignment.get('pickupZipCode')) {
    compInst.props.getServicesFromOptimus(
      customerNumber,
      subcustomer,
      pickupCountry,
      pickupZipCode,
      deliveryCountry,
      deliveryZipCode
    )
  }

  // glow-orders expects sms to be added as a vas, but it is just a bool on consignments, so add a vas here
  const hasSms = firstConsignment.get('deliverySms')
  const valueAddedServices = getValueAddedServices(firstConsignment, hasSms, copy)

  return compInst.props.mergeFormValues(
    formName,
    ImmutableMapFactory<IOrderFormProps>({
      emailId: order.get('emailId'),
      createdEvent: order.get('createdEvent'),
      collectedEvent: order.get('collectedEvent'),
      deliveredEvent: order.get('deliveredEvent'),
      pickupName: firstConsignment.get('pickupName'),
      pickupAddress: firstConsignment.get('pickupAddress') || '',
      pickupZipArea: firstConsignment.get('pickupZipArea'),
      pickupZipCode: firstConsignment.get('pickupZipCode'),
      pickupAddressLat: firstConsignment.get('pickupLat'),
      pickupAddressLng: firstConsignment.get('pickupLng'),
      pickupCountryCode: pickupCountry,
      pickupPhone: firstConsignment.get('pickupPhone'),
      pickupEmail: firstConsignment.get('pickupEmail'),
      pickupSecondPhone: firstConsignment.get('pickupSecondPhone'),
      pickupContactPerson: firstConsignment.get('pickupContactPerson') || '',
      deliveryName: firstConsignment.get('deliveryName'),
      deliveryAddress: firstConsignment.get('deliveryAddress') || '',
      deliveryZipArea: firstConsignment.get('deliveryZipArea'),
      deliveryZipCode: firstConsignment.get('deliveryZipCode'),
      deliveryAddressLat: firstConsignment.get('deliveryLat'),
      deliveryAddressLng: firstConsignment.get('deliveryLng'),
      deliveryCountryCode: deliveryCountry,
      deliveryPhone: firstConsignment.get('deliveryPhone'),
      deliveryEmail: firstConsignment.get('deliveryEmail'),
      deliverySecondPhone: firstConsignment.get('deliverySecondPhone'),
      deliveryContactPerson: firstConsignment.get('deliveryContactPerson') || '',
      customerRef: firstConsignment.get('customerRef') || '',
      recipientRef: firstConsignment.get('recipientRef') || '',
      pickupInstructions: firstConsignment.get('pickupInstructions'),
      deliveryInstructions: firstConsignment.get('deliveryInstructions'),
      orderNote: compInst.props.orderNote,
      customerId: firstConsignment.get('custAlystraId'),
      subcustomer: firstConsignment.get('custAlystraSubcustomer'),
      customerInfo: firstConsignment.get('information'),
      serviceId: getServiceId(firstConsignment, compInst.props.services, compInst.props.customizedServices),
      pickupTimeEarliest: hourMinute(firstConsignment.get('pickupTimeEarliestCached')),
      pickupDate: firstConsignment.get('pickupTimeEarliestCached')?.toISODate(),
      pickupTimeLatest: hourMinute(firstConsignment.get('pickupTimeLatestCached')),
      deliveryTimeEarliest: hourMinute(firstConsignment.get('deliveryTimeEarliestCached')),
      deliveryTimeLatest: hourMinute(firstConsignment.get('deliveryTimeLatestCached')),
      deliveryTimeWindowId: undefined,
      manualInvoiceInfo: order.get('manualInvoiceInfo'),
      invoiceInfo: order.get('invoiceInfo'),
      preplannedUnitId: firstConsignment.get('preplannedToUnit'),
      preplannedTemplateSlotId: order.get('preplannedTemplateSlotId')?.toString() || '',
      selectedDepartmentId: order.get('departmentIdOverridden') ? order.get('departmentId')?.toString() : null,
      packageIds: copy ? List() : compInst.props.consignments.map((c) => c.get('packageId')).toList(),
      groupId: copy ? '' : order.get('groupId'),
      groupName: copy ? '' : order.get('groupName'),
      groupOrder: copy ? undefined : order.get('groupOrder'),
      paidTogether: copy ? undefined : order.get('paidTogether'),
      vehicleType: order.get('vehicleType') || '',
      customerContact: order.get('customerContact') || '',
      measurements: compInst.props.consignments
        .map((c: Consignment) => {
          return Map({
            packageId: toString(c.get('packageId')),
            weight: toString(c.get('weightValue')) || '0',
            height: toString(c.get('heightValue')) || '0',
            length: toString(c.get('lengthValue')) || '0',
            width: toString(c.get('widthValue')) || '0',
            presetDimension: toString(c.get('presetDimension')),
            specificationDescription: c.get('specificationDescription'),
            volume: convert(c.get('volumeUnit'), MeasurementUnit.DMQ, c.get('volumeValue'))?.toString() || '0',
            packageTypeCode: c.get('packageTypeCode')
          }) as IMeasurement
        })
        .toList(),
      additionalServices: valueAddedServices,
      adrGoods,
      correctedArticlePrices: List(),
      invoiceSplit: order.get('invoiceSplit') ?? ''
    }).filter(isNotNullOrUndefined) as IOrderForm
  )
}

export type BookingProps = BookingStateProps & BookingDispatchProps

export const pickupComponentNames = List([
  'pickupName',
  'pickupLocation',
  'pickupAddress',
  'pickupZipCode',
  'pickupZipArea',
  'pickupCountryCode',
  'pickupContactPerson',
  'pickupPhone',
  'pickupInstructions'
])
export const deliveryComponentNames = List([
  'deliveryName',
  'deliveryLocation',
  'deliveryAddress',
  'deliveryZipCode',
  'deliveryZipArea',
  'deliveryCountryCode',
  'deliveryContactPerson',
  'deliveryPhone',
  'deliveryInstructions',
  'groupRecipients'
])
export const measurementComponentNames = List(['specificationDescription', 'measurements'])
export const serviceComponentNames = List(['serviceId', 'additionalServices'])
export const invoiceInformationComponentNames = List([
  'customerContact',
  'customerRef',
  'recipientRef',
  'invoiceInfo',
  'manualInvoiceInfo'
])
export const bookingPageComponentNames = List(['customerId', 'subcustomer', 'customerInfo']).concat(
  pickupComponentNames,
  deliveryComponentNames,
  measurementComponentNames,
  serviceComponentNames,
  invoiceInformationComponentNames
)

export const getCustomerEventEmailsData = (state: AppStateType): List<ICustomerEventEmail> =>
  state.getIn(['entities', 'customerEventEmails']) || List()

export const mergeDangerousGoods = (adrGoods: List<DangerousGoods> | undefined, dangerousGoods: List<DangerousGoods>) =>
  dangerousGoods.map((dangerousGood) => {
    const foundGoods = adrGoods?.find((it) => it.get('id') === dangerousGood.get('id'))
    return foundGoods ? foundGoods : dangerousGood
  })

export const acceptableMeasurements = (measurements: List<IMeasurement>) =>
  measurements.every(
    (m) =>
      (m.get('length') === '0' && m.get('width') === '0' && m.get('height') === '0') ||
      (m.get('length') !== '0' && m.get('width') !== '0' && m.get('height') !== '0')
  )
